var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"py-6",style:({ background: '#F7F8FD' })},[_c('div',{staticClass:"row"})]),_c('div',{staticClass:"container-fluid"},[_c('v-app',[_c('card',[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-6"},[_c('h2',{staticClass:"m-0"},[_vm._v("Staff Activity/Remark/Notes Report")])]),_c('div',{staticClass:"col-6 d-flex justify-content-end"},[_c('download-excel',{attrs:{"data":_vm.staffRemarkReport,"title":"Staff Activity Report","name":"staff-activity-report"}},[_c('base-button',{staticClass:"border-0 text-white bg-dark",staticStyle:{"backgroundcolor":"black"},attrs:{"icon":"fa fa-file-excel","size":"md"}})],1)],1)]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md"},[_c('v-autocomplete',{attrs:{"deletable-chips":"","menu-props":{
                closeOnClick: true,
                closeOnContentClick: true,
              },"small-chips":"","multiple":"","return-object":"","flat":"","clearable":"","item-text":"username","label":"Staff","items":_vm.staffList},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index < _vm.maxDisplay)?_c('v-chip',{on:{"click":_vm.filteredData.select}},[_vm._v(" "+_vm._s(item.username)+" ")]):_vm._e(),(index === _vm.maxDisplay)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.searchData.staff.length - _vm.maxDisplay)+" staffs)")]):_vm._e()]}}]),model:{value:(_vm.searchData.staff),callback:function ($$v) {_vm.$set(_vm.searchData, "staff", $$v)},expression:"searchData.staff"}},[_c('v-list-tile',{staticClass:"row-pointer p-3",attrs:{"slot":"prepend-item","ripple":""},on:{"click":_vm.toggleStaffSelect},slot:"prepend-item"},[_c('v-list-tile-action',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":_vm.searchData.staff.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.iconStaff))])],1),_c('v-list-tile-title',{staticClass:"ml-5 mt-3"},[_vm._v("Select All")])],1),_c('v-divider',{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"})],1)],1),_c('div',{staticClass:"col-md"},[_c('v-text-field',{attrs:{"type":"date","label":"Start Date","fab":""},model:{value:(_vm.searchData.from_date),callback:function ($$v) {_vm.$set(_vm.searchData, "from_date", $$v)},expression:"searchData.from_date"}})],1),_c('div',{staticClass:"col-md"},[_c('v-text-field',{attrs:{"type":"date","label":"End Date","fab":""},model:{value:(_vm.searchData.to_date),callback:function ($$v) {_vm.$set(_vm.searchData, "to_date", $$v)},expression:"searchData.to_date"}})],1),_c('div',{staticClass:"col-md mb-9"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Apply filters")]),_c('base-button',{staticClass:"w-100",attrs:{"type":"test","outline":""},on:{"click":_vm.filterReport}},[_vm._v("Search")])],1)]),(_vm.staffRemarkReport.length > 0)?_c('div',{staticClass:"table-responsive mt-4"},[_c('base-table',{staticClass:"align-items-center",attrs:{"thead-classes":"thead-light","type":"hover","data":_vm.staffRemarkReport},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.shop_name))]),_c('td',[_vm._v(_vm._s(row.remark))])]}}],null,false,3246128948)},[_c('template',{slot:"columns"},[_c('th',[_vm._v("Sl")]),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(header)+" ")])})],2)],2)],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('lottie-player',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":"https://assets1.lottiefiles.com/packages/lf20_lwnuxmxm.json","background":"transparent","speed":"1","loop":"","autoplay":""}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }