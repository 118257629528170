<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Add ASM</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>Name</span>
                <v-text-field
                  v-model="asmData.name"
                  class="mt-4"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Location</span>
                <v-text-field
                  v-model="asmData.location"
                  class="mt-4"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveASM()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      asmData: {
        name: "",
        location: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveASM() {

      let payload = {
        name: this.asmData.name,
        location: this.asmData.location
      }

      this.$store
        .dispatch("setAsmDetails", {
          payload
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to add asm",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
  },
  props: {
    dialog: {
      default: false,
    },
  },
};
</script>
