<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Edit ASM</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="asmData.name"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Location</span>
                <v-text-field
                  class="mt-4"
                  v-model="asmData.location"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-12">
                <span>Select User</span>
                <v-autocomplete
                  deletable-chips
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  :items="usersList"
                  return-object
                  v-model="asmData.user"
                  item-text="username"
                ></v-autocomplete>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveAsm()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      asmData: {
        name: "",
        location: "",
        user: null,
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveAsm() {
      let payload = {
        name: this.asmData.name,
        location: this.asmData.location,
        user: this.asmData.user.id || null,
      };

      this.$store
        .dispatch("setAsmDetails", {
          payload,
          asmID: this.id,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to edit asm",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchAsmDetails() {
      console.log("fetching");
      this.$store
        .dispatch("fetchAsmDetails", { asmID: this.id })
        .then(() => {
          this.prepareASM();
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to fetch asm details",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchUsersList() {
      this.$store.dispatch("fetchUsersList").catch((err) => {
        this.$notify({
          title: "Failed to fetch users list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    prepareASM() {
      this.asmData = JSON.parse(JSON.stringify(this.asmDetails));
    },
  },
  computed: {
    asmDetails() {
      return this.$store.state.asmStore.asmDetails;
    },
    usersList() {
      return this.$store.state.usersStore.users;
    },
  },
  props: {
    dialog: {
      default: false,
    },
    id: null,
  },
  watch: {
    id() {
      this.fetchAsmDetails();
      this.fetchUsersList();
    },
    dialog() {
      this.fetchAsmDetails();
      this.fetchUsersList();
    },
  },
};
</script>
