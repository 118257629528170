<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>

    <div class="container-fluid">
      <v-app>
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-6">
              <h2 class="m-0">Staff Activity/Remark/Notes Report</h2>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <download-excel
                :data="staffRemarkReport"
                title="Staff Activity Report"
                name="staff-activity-report"
              >
                <base-button
                  icon="fa fa-file-excel"
                  class="border-0 text-white bg-dark"
                  style="backgroundcolor: black"
                  size="md"
                >
                </base-button>
              </download-excel>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-md">
              <v-autocomplete
                deletable-chips
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                small-chips
                multiple
                return-object
                flat
                clearable
                item-text="username"
                v-model="searchData.staff"
                label="Staff"
                :items="staffList"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < maxDisplay"
                    @click="filteredData.select"
                  >
                    {{ item.username }} &nbsp;
                  </v-chip>

                  <span v-if="index === maxDisplay" class="grey--text caption"
                    >(+{{ searchData.staff.length - maxDisplay }} staffs)</span
                  >
                </template>
                <v-list-tile
                  class="row-pointer p-3"
                  slot="prepend-item"
                  ripple
                  @click="toggleStaffSelect"
                >
                  <v-list-tile-action class="mr-2">
                    <v-icon
                      :color="
                        searchData.staff.length > 0 ? 'indigo darken-4' : ''
                      "
                      >{{ iconStaff }}</v-icon
                    >
                  </v-list-tile-action>
                  <v-list-tile-title class="ml-5 mt-3"
                    >Select All</v-list-tile-title
                  >
                </v-list-tile>
                <v-divider slot="prepend-item" class="mt-2" />
              </v-autocomplete>
            </div>
            <div class="col-md">
              <v-text-field
                class=""
                type="date"
                v-model="searchData.from_date"
                label="Start Date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                class=""
                type="date"
                v-model="searchData.to_date"
                label="End Date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md mb-9">
              <label class="form-control-label">Apply filters</label>
              <base-button
                @click="filterReport"
                type="test"
                class="w-100"
                outline
                >Search</base-button
              >
            </div>
          </div>

          <div
            v-if="staffRemarkReport.length > 0"
            class="table-responsive mt-4"
          >
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="staffRemarkReport"
            >
              <template slot="columns">
                <th>Sl</th>
                <th v-for="(header, index) in tableHeaders" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td>{{ row.shop_name }}</td>
                <td>{{ row.remark }}</td>
              </template>
            </base-table>
          </div>
          <div v-else class="d-flex justify-content-center">
            <lottie-player
              src="https://assets1.lottiefiles.com/packages/lf20_lwnuxmxm.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 300px"
              loop
              autoplay
            ></lottie-player>
          </div>
        </card>
      </v-app>
    </div>
  </div>
</template>
<script>
export default {
  name: "staff-remark-report",
  data() {
    return {
      tableHeaders: ["Shop Name", "Remark"],
      maxDisplay: 2,
      searchData: {
        staff: [],
        from_date: "",
        to_date: "",
      },
      pagedTableData: [],
      filteredData: [],
      filteredHeaders: [],
    };
  },
  methods: {
    filterReport() {
      let error = undefined;

      if (this.searchData.staff.length === 0) {
        error = "Please select a staff";
      }

      let staffs = this.searchData.staff.map((item) => item.id);

      let from_date = this.searchData.from_date;
      let to_date = this.searchData.to_date;

      if (from_date === "") {
        from_date = null;
      }

      if (to_date === "") {
        to_date = null;
      }

      if (error) {
        this.$notify({
          title: error,
          icon: "fa fa-exclamation-triangle",
          type: "danger",
        });
      } else {
        let payload = {
          staffs,
          from_date,
          to_date,
        };
        this.$store
          .dispatch("fetchStaffRemarkReport", { payload })
          .catch((err) => {
            this.$notify({
              title: "Failed to fetch staff remark report",
              icon: "fa fa-exclamation-triangle",
              type: "danger",
              message: err.toString(),
            });
          });
      }
    },
    fetchStaffList() {
      this.$store.dispatch("fetchStaffList").catch((err) => {
        this.$notify({
          title: "Failed to fetch staff list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    toggleStaffSelect() {
      this.$nextTick(() => {
        if (this.selectAllStaff) {
          this.searchData.staff = [];
        } else {
          this.searchData.staff = this.staffList.slice();
        }
      });
    },
  },
  computed: {
    staffList() {
      return this.$store.state.staffStore.staffs;
    },
    staffRemarkReport() {
      return this.$store.state.reportStore.staffRemarkReport;
    },
    selectAllStaff() {
      return this.searchData.staff.length === this.staffList.length;
    },
    selectSomeStaff() {
      return this.searchData.staff.length > 0 && !this.selectAllStaff;
    },
    iconStaff() {
      if (this.selectAllStaff) return "mdi-close-box";
      if (this.selectSomeStaff) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {},
  mounted() {
    this.fetchStaffList();
  },
};
</script>
<style></style>
